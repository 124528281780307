/**
 * @license
 * MyFonts Webfont Build ID 3831126, 2019-10-31T04:57:47-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Lovelace-Light by Zetafonts
 * URL: https://www.myfonts.com/fonts/zetafonts/lovelace/light/
 * Copyright: Copyright © 2019 by Cosimo Lorenzo Pancini. All rights reserved.
 * Licensed pageviews: 10,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3831126
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/3a7556");*/

@font-face {
  font-family: 'Lovelace';
  src: url('lovelace/3A7556_0_0.eot');
  src: url('lovelace/3A7556_0_0.eot?#iefix') format('embedded-opentype'),
       url('lovelace/3A7556_0_0.woff2') format('woff2'),
       url('lovelace/3A7556_0_0.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/** 
 * Standard
 *
 * This font is a translation and modification of Berthold’s 1896 version of Standard.
 * Designed using Robofont
 *
 * URL: https://github.com/brycewilner/Standard
 *
 * Standard is available under the SIL Open Font License.
 * http://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL
*/
@font-face {
  font-family: 'Standard';
  src: url(standard/standard-book.eot);
  src: url('standard/standard-book.eot?#iefix') format('embedded-opentype'),
       url('standard/standard-book.woff2') format('woff2'),
       url('standard/standard-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Standard';
  src: url('standard/standard-bold.eot');
  src: url('standard/standard-bold.eot?#iefix') format('embedded-opentype'),
       url('standard/standard-bold.woff2') format('woff2'),
       url('standard/standard-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}